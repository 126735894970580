import { io, Socket } from "socket.io-client";
import { AppDispatch, store } from "../redux/store";
let socket: Socket | null = null;
const connect = (dispatch: AppDispatch) => {
    socket = io(process.env.REACT_APP_SOCKET + "/socket" || "", {
        transports: ['websocket'],
        reconnectionDelayMax: 10000,
        auth: {
            token: localStorage.getItem("LawcationToken")
        }
    });

    socket.io.on("open", () => {
        console.log('Socket connected')
    })

    // socket.on('validation-result', (data) => {
    //     console.log('validation-rejected', data);
    // })
    // socket.on('new-offer', (data) => {
    //     console.log("new offer", data)
    // });
    // socket.on('close-vacation', (data) => {
    //     console.log("close vacation", data)
    // });
}

const disconnect = () => {
    socket?.disconnect();
}

const getSocket = () => {
    return socket;
}

export default {
    getSocket,
    connect,
    disconnect
}