import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import attachmentReducer from "./slices/attachment";
import jurisdictionReducer from "./slices/jurisdiction";
import vacationReducer from "./slices/vacation";
import vacationTypeReducer from "./slices/vacation-type";
import offerReducer from "./slices/offer";
import chatReducer from "./slices/chat";
import deviceReducer from "./slices/device";
import cityReducer from "./slices/city";
import notificationReducer from "./slices/notification";
import activityReducer from "./slices/activity";
import releaseReducer from "./slices/release";

export const store = configureStore({
  reducer: {
    user: userReducer,
    attachment: attachmentReducer,
    jurisdiction: jurisdictionReducer,
    vacation: vacationReducer,
    vacationType: vacationTypeReducer,
    offer: offerReducer,
    chat: chatReducer,
    device: deviceReducer,
    city: cityReducer,
    notification: notificationReducer,
    activity: activityReducer,
    release: releaseReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


