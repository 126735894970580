import styled from "@emotion/styled";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

export function Loader({ size }: { size?: "small" }) {
  return (
    <div className="lds-spinner" style={{ transform: `${size === "small" ? "scale(0.3)" : "scale(0.7)"}` }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  );
}

function LoaderContainer() {
  return (
    <Root>
      <Loader />
    </Root>
  );
}

export default LoaderContainer;
