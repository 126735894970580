import { ReceiptLongOutlined } from "@mui/icons-material";

const pagesSection = [
  {
    href: "/user-layout/public-vacations",
    icon: ReceiptLongOutlined,
    title: "publicVacations",
  },
  {
    href: "/user-layout/vacations",
    icon: ReceiptLongOutlined,
    title: "myVacations",
  },
]

const navItems = [
  {
    title: "",
    pages: pagesSection,
  }
];

export default navItems;
