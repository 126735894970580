import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import PrivateLayout from "./layouts/PrivateLayout";
import MaOTP from "./pages/auth/MaOTP";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import Password from "./pages/auth/Password";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import UserLayout from "./layouts/UserLayout";

const HomePage = async(() => import("./pages/HomePage"));

// Admin routes
const AdminProfilePage = async(() => import("./pages/admin/profile/AdminProfilePage"));
const AdminUserPage = async(() => import("./pages/admin/user/AdminUserPage"));
const AdminAttachmentPage = async(() => import("./pages/admin/attachment/AdminAttachmentPage"));
const AdminJurisdictionPage = async(() => import("./pages/admin/jurisdiction/AdminJurisdictionPage"));
const AdminCityPage = async(() => import("./pages/admin/city/AdminCityPage"));
const AdminVacationPage = async(() => import("./pages/admin/vacation/AdminVacationPage"));
const AdminVacationTypePage = async(() => import("./pages/admin/vacation-type/AdminVacationTypePage"));
const AdminOfferPage = async(() => import("./pages/admin/offer/AdminOfferPage"));
const AdminChatPage = async(() => import("./pages/admin/chat/AdminChatPage"));
const AdminDevicePage = async(() => import("./pages/admin/device/AdminDevicePage"));
const AdminNotificationPage = async(() => import("./pages/admin/notification/AdminNotifPage"));
const AdminActivityPage = async(() => import("./pages/admin/activity/AdminActivityPage"));
const AdminReleasePage = async(() => import("./pages/admin/release/AdminReleasePage"))


// user
const UserVacationPage = async(() => import("./pages/user/vacation/UserVacationPage"));
const UserVacationPublicPage = async(() => import("./pages/user/vacation/UserVacationPublicPage"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "otp",
        element: <MaOTP />,
      },
      {
        path: "password",
        element: <Password />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "admin-layout",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <AdminProfilePage />,
      },
      {
        path: "users",
        element: <AdminUserPage />,
      },
      {
        path: "attachments",
        element: <AdminAttachmentPage />,
      },
      {
        path: "attachments",
        element: <AdminAttachmentPage />,
      },
      {
        path: "jurisdictions",
        element: <AdminJurisdictionPage />,
      },
      {
        path: "locations",
        element: <AdminCityPage />,
      },
      {
        path: "vacations",
        element: <AdminVacationPage />,
      },
      {
        path: "vacation-types",
        element: <AdminVacationTypePage />,
      },
      {
        path: "offers",
        element: <AdminOfferPage />,
      },
      {
        path: "chats",
        element: <AdminChatPage />,
      },
      {
        path: "devices",
        element: <AdminDevicePage />,
      },
      {
        path: "notifications",
        element: <AdminNotificationPage />,
      },
      {
        path: "activities",
        element: <AdminActivityPage />,
      },
      {
        path: "releases",
        element: <AdminReleasePage />,
      }
    ],
  },
  {
    path: "user-layout",
    element: (
      <AuthGuard>
        <UserLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <AdminProfilePage />,
      },
      {
        path: "public-vacations",
        element: <UserVacationPublicPage />,
      },
      {
        path: "vacations",
        element: <UserVacationPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
