import styled from "@emotion/styled";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  Alert as MuiAlert, Button, Grid, TextField as MuiTextField
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import LoaderContainer, { Loader } from "../LoaderContainer";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  return (
    <Formik
      initialValues={{
        phoneOrEmail: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        phoneOrEmail: Yup.string().required(t("phoneOrEmailRequired")),
        password: Yup.string().max(255).required(t("passwordRequired")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const role = await signIn(values.phoneOrEmail, values.password);
          navigate(`/${role.toLowerCase()}-layout/vacations`);
        } catch (error: any) {
          const message = error.message || t("loginFailed");
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="phoneOrEmail"
                label={t("phoneOrEmail")}
                value={values.phoneOrEmail}
                error={Boolean(touched.phoneOrEmail && errors.phoneOrEmail)}
                fullWidth
                helperText={touched.phoneOrEmail && errors.phoneOrEmail}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                name="password"
                label={t("password")}
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                size="small"
              >
                {t("login")}
              </Button>
              {isSubmitting && < LoaderContainer />}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
