import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { INotification, INotificationCreate } from "../../types/notification";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface NotificationState {
  deleteNotificationsStatus: StatusEnums;
  fetchNotificationsStatus: StatusEnums;
  fetchNotificationStatus: StatusEnums;
  addNotificationStatus: StatusEnums;
  notifications: IPagination<INotification> | null,
  notification: INotification | null,
  resError: IResError | null,
}

const initialState: NotificationState = {
  deleteNotificationsStatus: StatusEnums.IDLE,
  fetchNotificationsStatus: StatusEnums.IDLE,
  fetchNotificationStatus: StatusEnums.IDLE,
  addNotificationStatus: StatusEnums.IDLE,
  notifications: null,
  notification: null,
  resError: null,

};

export const deleteNotificationsAsync = createAsyncThunk(
  "attachments/deleteNotifications",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/notifications/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchNotificationAsync = createAsyncThunk(
  "notification/fetchNotification",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/notifications/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addNotificationAsync = createAsyncThunk(
  "notification/addNotification",
  async (notification: INotificationCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/notifications/add", notification);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchNotificationsAsync = createAsyncThunk(
  "notifications/fetchNotifications",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/notifications/search", filters);
    return response.data;
  }
);


export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetDeleteNotificationsStatus: (state) => {
      state.deleteNotificationsStatus = StatusEnums.IDLE;
    },
    resetFetchNotificationsStatus: (state) => {
      state.fetchNotificationsStatus = StatusEnums.IDLE;
    },
    resetAddNotificationStatus: (state) => {
      state.addNotificationStatus = StatusEnums.IDLE;
    },
    resetFetchNotificationStatus: (state) => {
      state.fetchNotificationStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // delete notifications 
      .addCase(deleteNotificationsAsync.pending, (state) => {
        state.deleteNotificationsStatus = StatusEnums.LOADING;
      })
      .addCase(deleteNotificationsAsync.fulfilled, (state) => {
        state.deleteNotificationsStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteNotificationsAsync.rejected, (state, action) => {
        state.deleteNotificationsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch notification
      .addCase(fetchNotificationAsync.pending, (state, action) => {
        state.fetchNotificationStatus = StatusEnums.LOADING;
      })
      .addCase(fetchNotificationAsync.fulfilled, (state, action) => {
        state.fetchNotificationStatus = StatusEnums.SUCCESS;
        state.notification = action.payload;
      })
      .addCase(fetchNotificationAsync.rejected, (state, action) => {
        state.fetchNotificationStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add notification
      .addCase(addNotificationAsync.pending, (state) => {
        state.addNotificationStatus = StatusEnums.LOADING;
      })
      .addCase(addNotificationAsync.fulfilled, (state, action) => {
        state.addNotificationStatus = StatusEnums.SUCCESS;
      })
      .addCase(addNotificationAsync.rejected, (state, action) => {
        state.addNotificationStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch notifications 
      .addCase(fetchNotificationsAsync.pending, (state, action) => {
        state.fetchNotificationsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchNotificationsAsync.fulfilled, (state, action) => {
        state.fetchNotificationsStatus = StatusEnums.SUCCESS;
        state.notifications = action.payload as IPagination<INotification>;
      })
      .addCase(fetchNotificationsAsync.rejected, (state, action) => {
        state.fetchNotificationsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetDeleteNotificationsStatus, resetFetchNotificationStatus, resetFetchNotificationsStatus, resetAddNotificationStatus } = notificationSlice.actions;


export const selectNotifications = (state: RootState) => state.notification.notifications;
export const selectNotification = (state: RootState) => state.notification.notification;
export const selectNotificationCount = (state: RootState) => state.notification.notifications?.count;
export const selectFetchNotificationsStatus = (state: RootState) => state.notification.fetchNotificationsStatus;
export const selectAddNotificationStatus = (state: RootState) => state.notification.addNotificationStatus;
export const selectFetchNotificationStatus = (state: RootState) => state.notification.fetchNotificationStatus;
export const selectDeleteNotificationsStatus = (state: RootState) => state.notification.deleteNotificationsStatus;
export const selectNotificationResError = (state: RootState) => state.notification.resError;

export default notificationSlice.reducer;
