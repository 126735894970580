import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Paper, Typography } from "@mui/material";
import SignInComponent from "../../components/auth/SignIn";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
function SignIn() {
  const { t } = useTranslation();
  return (
    <React.Fragment>

      <Wrapper>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
          <img src='/logo.png' width={100} />
        </div>

        <Helmet title={t('login')} />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("welcomeTitle")}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t("loginTitle")}
        </Typography>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}
export default SignIn;
