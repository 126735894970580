import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { AuthProvider } from "./contexts/JWTContext";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const clientSideEmotionCache = createEmotionCache();

function SnackbarCloseButton({ snackbarKey }: any) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton size="small" onClick={() => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
}

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Lawcation" defaultTitle="Lawcation - Admin" />
        <Provider store={store}>
          {/* @ts-ignore */}
          <LocalizationProvider dateAdapter={AdapterDateFns} dense>
            <MuiThemeProvider theme={createTheme(theme)}>
              <SnackbarProvider maxSnack={3} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>
                <AuthProvider>{content}</AuthProvider>
              </SnackbarProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
