import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions, DialogContent, IconButton, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { styled, useTheme } from '@mui/material/styles';
import Grow from '@mui/material/Grow';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    }
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


interface Props {
    open: boolean,
    loading?: boolean,
    onClose: () => void,
    title: string,
    children?: React.ReactNode;
    withDialogConent?: boolean;
    disableClickOutside?: boolean;
    minWidth?: number | string;
    width?: number | string;
    onScroll?: (event: any) => void;
    contentId?: string;
    forceFullScreen?: boolean;
}
export default function CustomDialog({ forceFullScreen, width = 900, minWidth = 900, children, open, onClose, title, withDialogConent, disableClickOutside, onScroll, contentId }: Props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <BootstrapDialog
            TransitionComponent={Grow}
            open={open}
            onClose={disableClickOutside ? () => { } : onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
            fullScreen={forceFullScreen || fullScreen}
            PaperProps={{
                style: {
                    minWidth: forceFullScreen ? "100%" : (width || minWidth),
                    maxHeight: forceFullScreen ? "100%" : 700,
                    width
                }
            }}
            scroll="paper"
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                {title}
            </BootstrapDialogTitle>
            {
                withDialogConent ? <><DialogContent dividers id={contentId} onScroll={onScroll}>
                    {children}
                </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} size="small" color="primary">Thoát</Button>
                    </DialogActions>
                </> : children
            }
        </BootstrapDialog >
    );
}
