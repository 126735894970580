import { File, Users } from "react-feather";
import { NaturePeopleOutlined, AccountBalanceOutlined, StarOutlineOutlined, ReceiptLongOutlined, LocalOfferOutlined, MessageOutlined, DevicesOtherOutlined, LocationCityOutlined, NotificationsOutlined, NewReleasesOutlined } from "@mui/icons-material";
const gneralSection = [
  {
    href: "/admin-layout/users",
    icon: Users,
    title: "accounts",
  },
  {
    href: "/admin-layout/vacations",
    icon: ReceiptLongOutlined,
    title: "vacations",
  },

  {
    href: "/admin-layout/offers",
    icon: LocalOfferOutlined,
    title: "offers",
  },
  {
    href: "/admin-layout/chats",
    icon: MessageOutlined,
    title: "messages",
  },
  {
    href: "/admin-layout/devices",
    icon: DevicesOtherOutlined,
    title: "devices",
  },
  {
    href: "/admin-layout/notifications",
    icon: NotificationsOutlined,
    title: "notifications",
  },
  {
    href: "/admin-layout/releases",
    icon: NewReleasesOutlined,
    title: "releases",
  },
]

const otherSection = [
  {
    href: "/admin-layout/activities",
    icon: NaturePeopleOutlined,
    title: "activities",
  },
  {
    href: "/admin-layout/attachments",
    icon: File,
    title: "attachments",
  },
  {
    href: "/admin-layout/jurisdictions",
    icon: AccountBalanceOutlined,
    title: "jurisdictions",
  },
  {
    href: "/admin-layout/locations",
    icon: LocationCityOutlined,
    title: "locations",
  },
  {
    href: "/admin-layout/vacation-types",
    icon: StarOutlineOutlined,
    title: "vacationTypes",
  },
]



const navItems = [
  {
    title: "general",
    pages: gneralSection,
  },
  {
    title: "other",
    pages: otherSection,
  },
];

export default navItems;
