import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import { IBuyService, IChangePassword, IUserCreate, IUserProfileUpdate, IUser, IStripePaymentMethod, IStripeBankAccount } from "../../types/user";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface UserState {
  fetchStripePaymentMethodStatus: StatusEnums;
  fetchStripeBankAccountStatus: StatusEnums;
  unlockUserStatus: StatusEnums;
  deleteUserStatus: StatusEnums;
  validateUserStatus: StatusEnums;
  addStripeCustomerStatus: StatusEnums;
  addUserSipStatus: StatusEnums;
  resetPasswordStatus: StatusEnums;
  changePasswordStatus: StatusEnums;
  buyServiceStatus: StatusEnums;
  deleteUsersStatus: StatusEnums;
  fetchUsersStatus: StatusEnums;
  fetchUsersAutoCompleteStatus: StatusEnums;
  uploadAvatarStatus: StatusEnums;
  deleteAvatarStatus: StatusEnums;
  addUserStatus: StatusEnums;
  updateUserStatus: StatusEnums;
  updateUserProfileStatus: StatusEnums;
  fetchUserStatus: StatusEnums;
  users: IPagination<IUser> | null,
  autoCompleteUsers: IPagination<IUser> | null,
  user: IUser | null,
  stripePaymentMethod: IStripePaymentMethod | null,
  stripeBankAccount: IStripeBankAccount | null,
  filters: IFilters | null,
  resError: IResError | null,
  showFilters: boolean

}

const initialState: UserState = {
  fetchStripePaymentMethodStatus: StatusEnums.IDLE,
  fetchStripeBankAccountStatus: StatusEnums.IDLE,
  unlockUserStatus: StatusEnums.IDLE,
  deleteUserStatus: StatusEnums.IDLE,
  validateUserStatus: StatusEnums.IDLE,
  addStripeCustomerStatus: StatusEnums.IDLE,
  addUserSipStatus: StatusEnums.IDLE,
  resetPasswordStatus: StatusEnums.IDLE,
  changePasswordStatus: StatusEnums.IDLE,
  buyServiceStatus: StatusEnums.IDLE,
  deleteUsersStatus: StatusEnums.IDLE,
  fetchUsersStatus: StatusEnums.IDLE,
  fetchUsersAutoCompleteStatus: StatusEnums.IDLE,
  uploadAvatarStatus: StatusEnums.IDLE,
  deleteAvatarStatus: StatusEnums.IDLE,
  addUserStatus: StatusEnums.IDLE,
  updateUserStatus: StatusEnums.IDLE,
  updateUserProfileStatus: StatusEnums.IDLE,
  fetchUserStatus: StatusEnums.IDLE,
  users: null,
  autoCompleteUsers: null,
  user: null,
  stripePaymentMethod: null,
  stripeBankAccount: null,
  filters: null,
  resError: null,
  showFilters: false

};

export const fetchStripePaymentMethodAsync = createAsyncThunk(
  "user/fetchStripePaymentMethod",
  async (id: string = "") => {
    const response = await axiosInstance.get("/api/users/get-payment-method/" + id);
    return response.data;
  }
);

export const fetchStripeBankAccountAsync = createAsyncThunk(
  "user/fetchStripeBankAccount",
  async (id: string = "") => {
    const response = await axiosInstance.get("/api/users/get-bank-account/" + id);
    return response.data;
  }
);


export const unlockUserAsync = createAsyncThunk(
  "user/unlockUserStatus",
  async (userId: string, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/unlock", { userId });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addStripeCustomerAsync = createAsyncThunk(
  "user/addUserStripeCustomer",
  async (userId: string, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/add-stripe-customer", { userId });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/deleteUser",
  async (id: string, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/dlete/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const verifyUserAsync = createAsyncThunk(
  "user/verify",
  async (payload: { id: string, action: any }, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/verify", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const addUserSipAsync = createAsyncThunk(
  "user/addUserSip",
  async (id: string, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/sip/add", { id });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const uploadAvatarAsync = createAsyncThunk(
  "user/uploadAvatar",
  async (uploadedFile: any, thunkApi) => {
    try {
      const formData = new FormData();
      formData.append("uploadedFile", uploadedFile);
      const response = await axiosInstance.post("/api/users/avatar/upload", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteAvatarAsync = createAsyncThunk(
  "user/deleteAvatar",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.delete("/api/users/avatar/delete");
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "user/resetPassword",
  async (payload: IBuyService, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/reset-password", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  "user/changePassword",
  async (payload: IChangePassword, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/change-password", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const buyServiceAsync = createAsyncThunk(
  "user/buyService",
  async (payload: IBuyService, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/buy-service", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteUsersAsync = createAsyncThunk(
  "user/deleteUsers",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchUserAsync = createAsyncThunk(
  "user/fetchUser",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/users/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchUsersAsync = createAsyncThunk(
  "user/fetchUsers",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/users/search", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchUsersAutoCompleteAsync = createAsyncThunk(
  "user/fetchUsersAutoComplete",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/users/search", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const addUserAsync = createAsyncThunk(
  "user/addUser",
  async (user: IUserCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/add", user);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/users/update/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateUserProfileAsync = createAsyncThunk(
  "user/updateUserProfile",
  async (payload: IUserProfileUpdate, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/users/me/update", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    showUserFilters: (state, action) => {
      state.showFilters = action.payload as boolean;
    },
    resetUserStatus: (state) => {
      state.addUserSipStatus = StatusEnums.IDLE;
      state.buyServiceStatus = StatusEnums.IDLE;
      state.addUserStatus = StatusEnums.IDLE;
      state.updateUserStatus = StatusEnums.IDLE;
      state.deleteUsersStatus = StatusEnums.IDLE;
      state.resetPasswordStatus = StatusEnums.IDLE;
      state.fetchUsersAutoCompleteStatus = StatusEnums.IDLE;
      state.uploadAvatarStatus = StatusEnums.IDLE;
      state.deleteAvatarStatus = StatusEnums.IDLE;
    },
    resetUpdateUserStatus: (state) => {
      state.updateUserStatus = StatusEnums.IDLE;
    },
    resetAddStripeCustomerStatus: (state) => {
      state.addStripeCustomerStatus = StatusEnums.IDLE;
    },
    resetUpdateUserProfileStatus: (state) => {
      state.updateUserProfileStatus = StatusEnums.IDLE;
    },
    resetDeleteUserStatus: (state) => {
      state.deleteUserStatus = StatusEnums.IDLE;
    },
    resetChangePasswordStatus: (state) => {
      state.changePasswordStatus = StatusEnums.IDLE;
    },
    resetFetchUserStatus: (state) => {
      state.fetchUserStatus = StatusEnums.IDLE;
    },
    resetValidaStatus: (state) => {
      state.validateUserStatus = StatusEnums.IDLE;
    },
    resetAddUserStatus: (state) => {
      state.addUserStatus = StatusEnums.IDLE;
    },
    setUserFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
    resetUnlockUserStatus: (state) => {
      state.unlockUserStatus = StatusEnums.IDLE;
    },
    resetFetchStripeBankAccountStatus: (state) => {
      state.fetchStripeBankAccountStatus = StatusEnums.IDLE;
    },
    resetFetchStripePaymentMethodStatus: (state) => {
      state.fetchStripePaymentMethodStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStripeBankAccountAsync.pending, (state) => {
        state.fetchStripeBankAccountStatus = StatusEnums.LOADING;
      })
      .addCase(fetchStripeBankAccountAsync.fulfilled, (state, action) => {
        state.fetchStripeBankAccountStatus = StatusEnums.SUCCESS;
        state.resError = null;
        state.stripeBankAccount = action.payload;
      })
      .addCase(fetchStripeBankAccountAsync.rejected, (state, action) => {
        state.fetchStripeBankAccountStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      .addCase(fetchStripePaymentMethodAsync.pending, (state) => {
        state.fetchStripePaymentMethodStatus = StatusEnums.LOADING;
      })
      .addCase(fetchStripePaymentMethodAsync.fulfilled, (state, action) => {
        state.fetchStripePaymentMethodStatus = StatusEnums.SUCCESS;
        state.resError = null;
        state.stripePaymentMethod = action.payload;
      })
      .addCase(fetchStripePaymentMethodAsync.rejected, (state, action) => {
        state.fetchStripePaymentMethodStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // unlock
      .addCase(unlockUserAsync.pending, (state) => {
        state.unlockUserStatus = StatusEnums.LOADING;
      })
      .addCase(unlockUserAsync.fulfilled, (state) => {
        state.unlockUserStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(unlockUserAsync.rejected, (state, action) => {
        state.unlockUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add stripe customer
      .addCase(addStripeCustomerAsync.pending, (state) => {
        state.addStripeCustomerStatus = StatusEnums.LOADING;
      })
      .addCase(addStripeCustomerAsync.fulfilled, (state) => {
        state.addStripeCustomerStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(addStripeCustomerAsync.rejected, (state, action) => {
        state.addStripeCustomerStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // validate user
      .addCase(deleteUserAsync.pending, (state) => {
        state.deleteUserStatus = StatusEnums.LOADING;
      })
      .addCase(deleteUserAsync.fulfilled, (state) => {
        state.deleteUserStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.deleteUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // validate user
      .addCase(verifyUserAsync.pending, (state) => {
        state.validateUserStatus = StatusEnums.LOADING;
      })
      .addCase(verifyUserAsync.fulfilled, (state) => {
        state.validateUserStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(verifyUserAsync.rejected, (state, action) => {
        state.validateUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // upload avatar 
      .addCase(addUserSipAsync.pending, (state) => {
        state.addUserSipStatus = StatusEnums.LOADING;
      })
      .addCase(addUserSipAsync.fulfilled, (state) => {
        state.addUserSipStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(addUserSipAsync.rejected, (state, action) => {
        state.addUserSipStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // upload avatar 
      .addCase(uploadAvatarAsync.pending, (state) => {
        state.uploadAvatarStatus = StatusEnums.LOADING;
      })
      .addCase(uploadAvatarAsync.fulfilled, (state) => {
        state.uploadAvatarStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(uploadAvatarAsync.rejected, (state, action) => {
        state.uploadAvatarStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete avatar 
      .addCase(deleteAvatarAsync.pending, (state) => {
        state.deleteAvatarStatus = StatusEnums.LOADING;
      })
      .addCase(deleteAvatarAsync.fulfilled, (state) => {
        state.deleteAvatarStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(deleteAvatarAsync.rejected, (state, action) => {
        state.deleteAvatarStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // change password 
      .addCase(changePasswordAsync.pending, (state) => {
        state.changePasswordStatus = StatusEnums.LOADING;
      })
      .addCase(changePasswordAsync.fulfilled, (state) => {
        state.changePasswordStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(changePasswordAsync.rejected, (state, action) => {
        state.changePasswordStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // reset service 
      .addCase(resetPasswordAsync.pending, (state) => {
        state.resetPasswordStatus = StatusEnums.LOADING;
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.resetPasswordStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.resetPasswordStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // buy service 
      .addCase(buyServiceAsync.pending, (state) => {
        state.buyServiceStatus = StatusEnums.LOADING;
      })
      .addCase(buyServiceAsync.fulfilled, (state) => {
        state.buyServiceStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(buyServiceAsync.rejected, (state, action) => {
        state.buyServiceStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete users 
      .addCase(deleteUsersAsync.pending, (state) => {
        state.deleteUsersStatus = StatusEnums.LOADING;
      })
      .addCase(deleteUsersAsync.fulfilled, (state) => {
        state.deleteUsersStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteUsersAsync.rejected, (state, action) => {
        state.deleteUsersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;

      })
      // fetch users 
      .addCase(fetchUsersAsync.pending, (state, action) => {
        state.fetchUsersStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.fetchUsersStatus = StatusEnums.SUCCESS;
        state.users = action.payload as IPagination<IUser>;
      })
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.fetchUsersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch users  auto complete
      .addCase(fetchUsersAutoCompleteAsync.pending, (state) => {
        state.fetchUsersAutoCompleteStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUsersAutoCompleteAsync.fulfilled, (state, action) => {
        state.fetchUsersAutoCompleteStatus = StatusEnums.SUCCESS;
        state.autoCompleteUsers = action.payload as IPagination<IUser>;
      })
      .addCase(fetchUsersAutoCompleteAsync.rejected, (state, action) => {
        state.fetchUsersAutoCompleteStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add user
      .addCase(addUserAsync.pending, (state) => {
        state.addUserStatus = StatusEnums.LOADING;
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.addUserStatus = StatusEnums.SUCCESS;
        setImmediate(() => {
          state.addUserStatus = StatusEnums.IDLE;
        })
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.addUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch user
      .addCase(fetchUserAsync.pending, (state) => {
        state.fetchUserStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.fetchUserStatus = StatusEnums.SUCCESS;
        state.user = action.payload;
      })
      .addCase(fetchUserAsync.rejected, (state, action) => {
        state.fetchUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update user
      .addCase(updateUserAsync.pending, (state) => {
        state.updateUserStatus = StatusEnums.LOADING;
      })
      .addCase(updateUserAsync.fulfilled, (state) => {
        state.updateUserStatus = StatusEnums.SUCCESS;
        state.user = null;
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.updateUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update user profile
      .addCase(updateUserProfileAsync.pending, (state) => {
        state.updateUserProfileStatus = StatusEnums.LOADING;
      })
      .addCase(updateUserProfileAsync.fulfilled, (state) => {
        state.updateUserProfileStatus = StatusEnums.SUCCESS;
      })
      .addCase(updateUserProfileAsync.rejected, (state, action) => {
        state.updateUserProfileStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      ;
  },
});

export const { resetFetchStripePaymentMethodStatus, resetFetchStripeBankAccountStatus, resetUpdateUserStatus, resetUnlockUserStatus, resetAddStripeCustomerStatus, resetUpdateUserProfileStatus, resetDeleteUserStatus, resetChangePasswordStatus, resetFetchUserStatus, resetValidaStatus, resetAddUserStatus, resetUserStatus, setUserFilters, showUserFilters } = userSlice.actions;


export const selectFetchStripeBankAccountStatus = (state: RootState) => state.user.fetchStripeBankAccountStatus;
export const selectFetchStripePaymentMethodStatus = (state: RootState) => state.user.fetchStripePaymentMethodStatus;
export const selectStripeBankAccount = (state: RootState) => state.user.stripeBankAccount;
export const selectStripePaymentMethod = (state: RootState) => state.user.stripePaymentMethod;
export const selectUnlockUserStatus = (state: RootState) => state.user.unlockUserStatus;
export const selectAddStripeCustomerStatus = (state: RootState) => state.user.addStripeCustomerStatus;
export const selectUsers = (state: RootState) => state.user.users;
export const selectAutoCompleteUsers = (state: RootState) => state.user.autoCompleteUsers;
export const selectUser = (state: RootState) => state.user.user;
export const selectUserFilters = (state: RootState) => state.user.filters;
export const selectUserCount = (state: RootState) => state.user.users?.count;
export const selectBuyServiceStatus = (state: RootState) => state.user.buyServiceStatus;
export const selectDeleteUsersStatus = (state: RootState) => state.user.deleteUsersStatus;
export const selectFetchUsersStatus = (state: RootState) => state.user.fetchUsersStatus;
export const selectAddUserStatus = (state: RootState) => state.user.addUserStatus;
export const selectFetchUserStatus = (state: RootState) => state.user.fetchUserStatus;
export const selectUpdateUserStatus = (state: RootState) => state.user.updateUserStatus;
export const selectUpdateUserProfileStatus = (state: RootState) => state.user.updateUserProfileStatus;
export const selectResetPasswordStatus = (state: RootState) => state.user.resetPasswordStatus;
export const selectChangePasswordStatus = (state: RootState) => state.user.changePasswordStatus;
export const selectFetchUsersAutoCompleteStatus = (state: RootState) => state.user.fetchUsersAutoCompleteStatus;
export const selectUploadAvatarStatus = (state: RootState) => state.user.uploadAvatarStatus;
export const selectDeleteAvatarStatus = (state: RootState) => state.user.deleteAvatarStatus;
export const selectAddUserSipStatus = (state: RootState) => state.user.addUserSipStatus;
export const selectUserResError = (state: RootState) => state.user.resError;
export const selectShowUserFilters = (state: RootState) => state.user.showFilters;
export const selectVerifyUserStatus = (state: RootState) => state.user.validateUserStatus;
export const selectDeleteUserStatus = (state: RootState) => state.user.deleteUserStatus;

export default userSlice.reducer;
